export const HomeSpanish =
{
    title1: '¿NECESITÁS TU SITIO WEB?',
    title2: 'ESE ES NUESTRO TRABAJO.',
    title3: 'Desarrollamos Software',
}

export const HomeEnglish =
{
    title1: 'DO YOU NEED A WEBSITE?',
    title2: "THAT'S OUR JOB.",
    title3: 'We develop software.',
}